<template>
  <div :id="viewId" class="page-wrapper" :class="{ legacy: !isDefaultView }">
    <HeaderComponent
      :theme-color="headerThemeColor"
      :home-link="homeLink"
      :navigation="navigation"
      :featured-navigation="featuredNavigation"
      :minor-navigation="minorNavigation"
      :social-navigation="socialNavigation"
      :locale-navigation="localeNavigation"
    />

    <DelayHydration>
      <Breadcrumbs
        :navigation="breadcrumbNavigation"
        :base-url="baseUrl"
        :theme-color="headerThemeColor"
        :is-transparent="isBreadcrumbsTransparent"
        :is-dark-font="isBreadcrumbsDarkFont"
      />
    </DelayHydration>

    <slot />

    <FooterComponent
      :navigation="footerNavigation"
      :social-navigation="socialNavigation"
      :contact-data="footerContactData"
      :is-main-page="isMainPage"
    />

    <GeoipLocaleSwitcher />
  </div>
</template>

<script setup lang="ts">
import { withoutTrailingSlash } from 'ufo'
import { computed, defineAsyncComponent, ref } from 'vue'

import { useNuxtApp } from '#app'
import { useCurrentLocale, useT3Api, useT3Options } from '#imports'

import type { ILocaleNavigation, INavigation, IPageBreadcrumbs } from '~/types'

const HeaderComponent = defineAsyncComponent(
  () => import('~shared/components/Base/HeaderComponent')
)
const Breadcrumbs = defineAsyncComponent(
  () => import('~ui/components/Base/Breadcrumbs/Breadcrumbs.vue')
)
const GeoipLocaleSwitcher = defineAsyncComponent(
  () => import('~shared/components/Base/GeoipLocaleSwitcher')
)
const FooterComponent = defineAsyncComponent(
  () => import('~shared/components/Base/FooterComponent')
)

const { options } = useT3Options()
const currentLocale = useCurrentLocale()
const { initialData: newInitialData, pageData: newPageData } = useT3Api()

const { hook } = useNuxtApp()
const initialData = ref(newInitialData.value)
const pageData = ref(newPageData.value)

hook('page:loading:end', () => {
  initialData.value = newInitialData.value
  pageData.value = newPageData.value
})

const viewIdMap = [
  {
    idSuffix: 'ContactPage',
    validator: () => pageData.value?.type === 'Contact'
  }
]

const viewId = computed(() => {
  if (!Array.isArray(viewIdMap) || !viewIdMap.length) return undefined

  const view = viewIdMap.find(
    (view) => view?.validator && view.validator() && view?.idSuffix
  )

  return view ? `view--${view.idSuffix}` : undefined
})

// Shared
const socialNavigation = computed(() => {
  const navigation = initialData.value?.socialMenu
  return Array.isArray(navigation) ? navigation : []
})

// > Header
const headerThemeColor = computed(() => {
  if (!pageData.value?.appearance?.headerTransparent) return undefined
  const themeColor = pageData.value?.content?.colPos0?.[0]?.appearance?.color
  return themeColor ? `theme-${themeColor}` : undefined
})

const homeLink = computed(() =>
  currentLocale.value === options.i18n.default ? '/' : `/${currentLocale.value}`
)

const navigation = computed(() => {
  const navigation = initialData.value?.navigation?.[0]?.children
  return Array.isArray(navigation) ? (navigation as Array<INavigation>) : []
})

const featuredNavigation = computed(() => {
  const navigation = initialData.value?.featuredNavigation
  return Array.isArray(navigation) ? (navigation as Array<INavigation>) : []
})

const minorNavigation = computed(() => {
  const navigation = initialData.value?.minorNavigation
  return Array.isArray(navigation) ? (navigation as Array<INavigation>) : []
})

const localeNavigation = computed(() => {
  const localeArray = pageData.value?.i18n

  if (!Array.isArray(localeArray) || !localeArray.length) return []

  return localeArray.map((locale) => ({
    language: locale.navigationTitle,
    locale: locale.twoLetterIsoCode,
    localePath: withoutTrailingSlash(locale.link),
    active: locale.active,
    available: locale.available
  })) as Array<ILocaleNavigation>
})

// > Breadcrumbs
const baseUrl = computed(() => options.baseUrl!)

const breadcrumbNavigation = computed(() => {
  const navigation = pageData.value?.breadcrumbs as IPageBreadcrumbs
  if (!Array.isArray(navigation) || !navigation.length) return []
  return navigation.map((link) => ({
    ...link,
    link: withoutTrailingSlash(link.link)
  }))
})

const isBreadcrumbsTransparent = computed(() => {
  return pageData.value?.appearance?.breadcrumbsTransparent
})

const isBreadcrumbsDarkFont = computed(() => {
  return pageData.value?.appearance?.breadcrumbsDarkFont
})

// > Footer
const footerNavigation = computed(() => {
  const navigation = initialData.value?.footerNavigation
  return Array.isArray(navigation) ? navigation : []
})

const footerContactData = computed(() => {
  const data = initialData.value?.footerContactData?.content?.items
  if (!Array.isArray(data)) return []
  return data.map(({ id, content }) => ({ id, ...content }))
})

const isMainPage = computed(() => !options.api.baseUrl?.includes('t3headless'))

const isDefaultView = computed(() => {
  if (options.api.baseUrl?.includes('t3headless')) return false
  return !!featuredNavigation.value.filter((link) => !link.spacer).length
})
</script>
